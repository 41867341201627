// eslint-disable-next-line import/prefer-default-export

export const getSetting = /* GraphQL */ `
    query GetSetting($key: String!) {
        getSetting(key: $key) {
            key
            value
        }
    }
`;

export const getUser = /* GraphQL */ `
    query GetUser($id: ID!) {
        getUser(id: $id) {
            id
            username
            name {
                first
                last
            }
            ensembleIds
            instrumentIds
        }
    }
`;

export const listEnsembles = /* GraphQL */ `
    query ListEnsembles(
        $id: ID
        $filter: ModelEnsembleFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listEnsembles(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                slug
                name
                code
                parts
                instruments {
                    items {
                        instrument {
                            id
                        }
                    }
                    nextToken
                }
            }
            nextToken
        }
    }
`;

export const listInstruments = /* GraphQL */ `
    query ListInstruments(
        $id: ID
        $filter: ModelInstrumentFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listInstruments(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                order
            }
            nextToken
        }
    }
`;

export const listSelections = /* GraphQL */ `
    query ListSelections(
        $ensembleId: ID
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSelections(
            filter: { selectionEnsembleId: { eq: $ensembleId }}
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                part
                ranking {
                    state
                }
                ensemble {
                    id
                    name
                }
                accepted
                application {
                    id
                    student {
                        id
                        name {
                            first
                            last
                        }
                        grade
                        school {
                            name {
                                legal
                            }
                        }
                        applications {
                            items {
                                id
                                applicationInstrumentId
                                instrument {
                                    id 
                                    name
                                }
                                selection {
                                    id
                                    part
                                    ranking {
                                        state
                                    }
                                    ensemble {
                                        id  
                                        name
                                    }
                                    accepted
                                    selectionEnsembleId
                                }
                            }
                        }
                    }
                    instrument {
                        id
                        name
                        order
                    }
                    ranking {
                        local
                        score
                    }
                    festival {
                        name
                    }
                    applicationInstrumentId
                    applicationSelectionId
                }
                state {
                    order
                }
                createdAt
                updatedAt
                selectionEnsembleId
            }
            nextToken
        }
    }
`;

export const listSelectionsWithNullEnsemble = /* GraphQL */ `
    query ListSelections(
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSelections(
            filter: { selectionEnsembleId: { attributeExists: false }}
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                part
                ranking {
                    state
                }
                ensemble {
                    id
                    name
                }
                accepted
                application {
                    id
                    student {
                        id
                        name {
                            first
                            last
                        }
                        grade
                        school {
                            name {
                                legal
                            }
                        }
                        applications {
                            items {
                                id
                                applicationInstrumentId
                                instrument {
                                    id
                                    name
                                }
                                selection {
                                    id
                                    part
                                    ranking {
                                        state
                                    }
                                    ensemble {
                                        id
                                        name
                                    }
                                    accepted
                                    selectionEnsembleId
                                }
                            }
                        }
                    }
                    instrument {
                        id
                        name
                        order
                    }
                    ranking {
                        local
                        score
                    }
                    festival {
                        name
                    }
                    applicationInstrumentId
                    applicationSelectionId
                }
                state {
                    order
                }
                createdAt
                updatedAt
                selectionEnsembleId
            }
            nextToken
        }
    }
`;

export const getSelection = /* GraphQL */ `
    query GetSelection($id: ID!) {
        getSelection(id: $id) {
            id
            part
            ranking {
                state
            }
            ensemble {
                id
                name
            }
            accepted
            application {
                id
                student {
                    id
                    name {
                        first
                        last
                    }
                    grade
                    school {
                        name {
                            legal
                        }
                    }
                    applications {
                        items {
                            id
                            applicationInstrumentId
                            instrument {
                                id
                                name
                            }
                            selection {
                                id
                                part
                                ranking {
                                    state
                                }
                                ensemble {
                                    id
                                    name
                                }
                                accepted
                                selectionEnsembleId
                            }
                        }
                    }
                }
                instrument {
                    id
                    name
                    order
                }
                ranking {
                    local
                    score
                }
                festival {
                    name
                }
                applicationInstrumentId
                applicationSelectionId
            }
            state {
                order
            }
            createdAt
            updatedAt
            selectionEnsembleId
        }
    }
`;

export const updateSelection = /* GraphQL */ `
    mutation UpdateSelection(
        $input: UpdateSelectionInput!
        $condition: ModelSelectionConditionInput
    ) {
        updateSelection(input: $input, condition: $condition) {
            id
            part
            ranking {
                state
            }
            accepted
            application {
                id
                student {
                    id
                    name {
                        first
                        last
                    }
                    grade
                    school {
                        name {
                            legal
                        }
                    }
                    applications {
                        items {
                            id
                            applicationInstrumentId
                            selection {
                                id
                                part
                                ranking {
                                    state
                                }
                                accepted
                                selectionEnsembleId
                            }
                        }
                    }
                }
                instrument {
                    id
                    name
                    order
                }
                ranking {
                    local
                    score
                }
                festival {
                    name
                }
                applicationInstrumentId
                applicationSelectionId
            }
            state {
                order
            }
            createdAt
            updatedAt
            selectionEnsembleId
        }
    }
`;

export const onCreateSelection = /* GraphQL */ `
    subscription OnCreateSelection(
        $filter: ModelSubscriptionSelectionFilterInput
    ) {
        onCreateSelection(filter: $filter) {
            id
            part
            ranking {
                state
            }
            ensemble {
                id
                slug
                name
                code
                parts
                instruments {
                    nextToken
                }
                hasParts
                hasAlternates
                state {
                    enabled
                }
                createdAt
                updatedAt
            }
            accepted
            selectionEnsembleId
            selectionApplicationId
        }
    }
`;
export const onUpdateSelection = /* GraphQL */ `
    subscription OnUpdateSelection(
        $filter: ModelSubscriptionSelectionFilterInput
    ) {
        onUpdateSelection(filter: $filter) {
            id
            part
            ranking {
                state
            }
            accepted
            application {
                id
                student {
                    id
                    name {
                        first
                        last
                    }
                    grade
                    school {
                        name {
                            legal
                        }
                    }
                    applications {
                        items {
                            id
                            applicationInstrumentId
                            selection {
                                id
                                part
                                ranking {
                                    state
                                }
                                accepted
                                selectionEnsembleId
                            }
                        }
                    }
                }
                instrument {
                    id
                    name
                    order
                }
                ranking {
                    local
                    score
                }
                festival {
                    name
                }
                applicationInstrumentId
                applicationSelectionId
            }
            state {
                order
            }
            selectionEnsembleId
            selectionApplicationId
        }
    }
`;
export const onDeleteSelection = /* GraphQL */ `
    subscription OnDeleteSelection(
        $filter: ModelSubscriptionSelectionFilterInput
    ) {
        onDeleteSelection(filter: $filter) {
            id
            selectionEnsembleId
            selectionApplicationId
        }
    }
`;

